import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
// import { FormText } from 'react-bootstrap';

export default function RootLayout() {
    return (
        <div>
            <Header className=""/> 
            <main className="mt-24">
                < Outlet />
            </main>
            <Footer />
        </div>
    )

};