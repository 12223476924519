import { useEffect, useState } from 'react';
import { useAuth } from '../user-auth/AuthContext'; 

import { CustomOverlay } from '../components/overlays/Overlays';
import { CardProperty } from '@/recommendation/CardProperty';

import VisualizationCard_v3 from '../database-demo/visualization-card/VisualizationCard_v3';

import Error from '../database-demo/loading-error-handling/Error';

const urlBackendGetProperties = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/get-properties/";

export default function GetSavedProperties() {
    const { isLoggedIn } = useAuth();
    const [properties, setProperties] = useState([]);
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    const [selectedProperty, setSelectedProperty] = useState(null);                

    // open Custom Overlay 
    const [isOpen, setIsOpen] = useState(false); 

    useEffect(() => {
        if (isLoggedIn) {
            const fetchProperties = async () => {
                try {
                    const response = await fetch(urlBackendGetProperties, {
                        method: 'GET',
                        credentials: 'include', // Ensure cookies are sent with the request
                    });

                    if (response.ok) {
                        const data = await response.json();
                        // console.log(data);
                        setProperties(data.saved_properties); 
                        setChats(data.chatted_properties); 
                    } else {
                        const errorData = await response.json();
                        setError(errorData.error || "An error occurred while fetching properties.");
                    }
                } catch (error) {
                    setError("An error occurred while fetching properties.");
                } finally {
                    setLoading(false);
                }
            };

            fetchProperties();
        } else {
            setProperties([]); // Clear properties if not logged in
        }
    }, [isLoggedIn]);

    const handleVisualization = (property) => {                        
        setSelectedProperty(property);        
        setIsOpen(true);
    }

    if (error) {
        return (
            <div className='row'>
                <Error/>
            </div>  
        )
    } 

    return (
        <div className='max-w-screen-xl'>

            <div className='my-8 text-center'>
                <h3>Saved Properties</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {loading 
                    ? Array(6).fill().map((_, index) => <CardProperty key={index} isLoading={true} />)
                    : properties.map(property => (
                        <CardProperty
                          key={property.propertyID}
                          addressInfo={property.addressInfo}
                          isLoading={false}
                          onClick={() => handleVisualization(property)}
                        />
                      )) 
                }                
            </div>  

            <div className='my-8 text-center'>
                <h3>Recent Title Chats</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {loading 
                    ? Array(6).fill().map((_, index) => <CardProperty key={index} isLoading={true} />)
                    : chats.map(property => (
                        <CardProperty
                          key={property.propertyID}
                          addressInfo={property.addressInfo}
                          isLoading={false}
                          onClick={() => handleVisualization(property)}
                        />
                      )) 
                }                
            </div> 
                                   
            <CustomOverlay isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {selectedProperty && (
                    <VisualizationCard_v3
                        dataJSON={selectedProperty}
                    />
                )}
            </CustomOverlay>

        </div>
    );
}